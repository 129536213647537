import Image from "next/image";
import codeIcon from "@/images/icons/promocode.svg";
import dateIcon from "@/images/icons/datepicker.svg";
import guestIcon from "@/images/icons/guests.svg";
import hotelIconWhite from "@/images/icons/hotel-white.svg";
import hotelIconBlue from "@/images/icons/hotel-blue.svg";
import hotelFlyIconWhite from "@/images/icons/hotel-vuelo-white.svg";
import hotelFlyIconBlue from "@/images/icons/hotel-vuelo-blue.svg";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import Button from "../atoms/Button";
import classNames from "classnames";
import { packagesUrl, paratyUrl } from "@/utils/api";
import * as BookingAttempt from "axovia-abt/dist/BookingAttempt";
import dynamic from "next/dynamic";
import BookingModal from "./BookingModal";
const GuestModal = dynamic(() => import("../molecules/GuestModal"));

const Booking = () => {
  const today = new Date();
  const { t } = useTranslation("common");
  const INIT_URL = paratyUrl[t("common:locale")];
  const bookingTravelApi = packagesUrl[t("common:locale")];
  const [adults, setAdults] = useState(2);
  const [startDate, setStartDate] = useState(
    new Date(today.valueOf() + 86400000)
  );
  const [endDate, setEndDate] = useState(
    new Date(startDate.valueOf() + 86400000)
  );
  const [bookingUrl, setBookingUrl] = useState(INIT_URL);
  const [rooms, setRooms] = useState([
    {
      adults: 2,
    },
  ]);
  const [roomsModalIsOpen, setRoomsModalOpen] = useState(false);
  const [active, setActive] = useState("1");
  const [promoCode, setPromoCode] = useState("")
  const addRoom = () => {
    if (rooms.length < 4) {
      setRooms((rooms) => [
        ...rooms,
        {
          adults: 2,
        },
      ]);
    }
  };

  const removeRoom = () => {
    if (rooms.length > 1) {
      setRooms((rooms) => rooms.slice(0, -1));
    }
  };

  const updateOccupancy = (type, key) => {
    const updateRooms = rooms.map((room, index) => {
      if (
        index === key &&
        (room.adults > 1 || type === "add") &&
        (room.adults < 3 || type === "remove")
      ) {
        return { adults: type === "add" ? room.adults + 1 : room.adults - 1 };
      } else {
        return { adults: room.adults };
      }
    });
    setRooms(updateRooms);
  };

  const [isFromMobile, setIsFromMobile] = useState(false);
  /* ONBLUR ROOMS */
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!isFromMobile) {
          setRoomsModalOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, isFromMobile]);

  /* END ONBLUR ROOMS */
  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(new Date(startDate.valueOf() + 86400000));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let url = INIT_URL;
    let totalRooms = rooms.length
    let adultsPerRoomQuery = ""
    for(let i =0; i<totalRooms; i++){
      let key = i+1
      adultsPerRoomQuery=adultsPerRoomQuery+`adultsRoom${key}=${rooms[i].adults}&`
    }
    url = `${url}&startDate=${moment(startDate).format("DD/MM/YYYY")}&endDate=${moment(
      endDate
    ).format("DD/MM/YYYY")}&numRooms=${totalRooms}&${adultsPerRoomQuery}promocode=${promoCode}`;
    setBookingUrl(url);
  }, [INIT_URL, startDate, endDate, rooms, promoCode]);
  let roomsText = "";
  let adultsText = "";
  if (rooms.length > 1) {
    roomsText = t("common:booking.room") || "";
    adultsText = t("common:booking.adults" || "");
  } else {
    roomsText = t("common:booking.rooms") || "";
    if (adults > 1) {
      adultsText = t("common:booking.adults" || "");
    } else {
      adultsText = t("common:booking.adult" || "");
    }
  }
  useEffect(() => {
    let counterAdults = 0;
    rooms.map((room, index) => {
      counterAdults += room.adults;
    });
    setAdults(counterAdults);
    if (rooms.length > 1) {
      roomsText = t("common:booking.room") || "";
      adultsText = t("common:booking.adults" || "");
    } else {
      roomsText = t("common:booking.rooms") || "";
      if (adults > 1) {
        adultsText = t("common:booking.adults" || "");
      } else {
        adultsText = t("common:booking.adult" || "");
      }
    }
  }, [rooms]);

  const [modalMobileOpen, setModalMobileOpen] = useState(false);

  return (
    <div>
      {modalMobileOpen && (
        <BookingModal
          active={active}
          setActive={setActive}
          hotelIconWhite={hotelIconWhite}
          hotelFlyIconBlue={hotelFlyIconBlue}
          hotelIconBlue={hotelIconBlue}
          t={t}
          hotelFlyIconWhite={hotelFlyIconWhite}
          wrapperRef={wrapperRef}
          roomsModalIsOpen={roomsModalIsOpen}
          guestIcon={guestIcon}
          rooms={rooms}
          roomsText={roomsText}
          adults={adults}
          adultsText={adultsText}
          setRoomsModalOpen={setRoomsModalOpen}
          GuestModal={GuestModal}
          addRoom={addRoom}
          removeRoom={removeRoom}
          updateOccupancy={updateOccupancy}
          dateIcon={dateIcon}
          DatePicker={DatePicker}
          startDate={startDate}
          today={today}
          endDate={endDate}
          codeIcon={codeIcon}
          bookingUrl={bookingUrl}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          BookingAttempt={BookingAttempt}
          setRooms={setRooms}
          setIsFromMobile={setIsFromMobile}
          setModalMobileOpen={setModalMobileOpen}
          setPromoCode={setPromoCode}
          promoCode={promoCode}
        />
      )}
      <div className="fixed bottom-3 z-20 left-3 md:left-0 right-3 md:right-0">
        <div className="lg:bg-white base-container base-grid p-0 gap-0 max-w-7xl w-[83vw] ">
          <div className="col-span-10 hidden lg:block">
            <div className="grid grid-cols-12 gap-0">
              <div className="col-span-2 flex border-r border-main-blue">
                <div
                  className={classNames(
                    "w-2/5 grid",
                    active === "1" ? "bg-main-blue" : "bg-white"
                  )}
                  onClick={() => setActive("1")}
                >
                  <Image
                    src={active === "1" ? hotelIconWhite : hotelIconBlue}
                    width={22}
                    height={22}
                    alt="date"
                    className="mx-auto h-1/2 mt-auto"
                  />
                  <p
                    className={classNames(
                      "font-subtitle text-[16px]  my-auto text-xs  text-center uppercase",
                      active === "1" ? "text-white" : "text-main-blue"
                    )}
                  >
                    HOTEL
                  </p>
                </div>
                <div
                  className={classNames(
                    "w-3/5 ",
                    active === "2" ? "bg-main-blue" : "bg-white"
                  )}
                  onClick={() => setActive("2")}
                >
                  <Link
                    href={t("common:hotel_fly_href")}
                    className="grid h-full"
                    target="_blank"
                  >
                    <Image
                      src={
                        active === "2" ? hotelFlyIconWhite : hotelFlyIconBlue
                      }
                      width={60}
                      height={26}
                      alt="date"
                      className="mx-auto h-1/2 mt-auto"
                    />
                    <p
                      className={classNames(
                        "font-subtitle text-[16px] my-auto text-xs  text-center uppercase",
                        active === "2" ? "text-white" : "text-main-blue"
                      )}
                    >
                      {t("common:hotel_fly")}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-span-2  pl-3 border-r border-main-blue py-1">
                <label
                  htmlFor="startDate"
                  className="font-subtitle text-[12px] text-medium-grey uppercase"
                >
                  Check-in
                </label>
                <div className="grow flex items-center">
                  <Image src={dateIcon} alt="date" className="mr-2" />
                  <DatePicker
                    id="startDate"
                    closeOnScroll={true}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setTimeout(() => {
                        document.getElementById("endDate").click();
                      }, 250);
                    }}
                    minDate={new Date(today.valueOf() + 86400000)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="font-subtitle text-[16px]  text-main-blue placeholder:text-main-blue w-full outline-none"
                  />
                </div>
              </div>
              <div className="col-span-2  pl-3 border-r border-main-blue py-1">
                <label
                  htmlFor="endDate"
                  className="font-subtitle text-[12px] text-medium-grey uppercase"
                >
                  Check-out
                </label>
                <div className="grow flex items-center">
                  <Image src={dateIcon} alt="date" className="mr-2" />
                  <DatePicker
                    id="endDate"
                    closeOnScroll={true}
                    selected={endDate}
                    onChange={(date) => {
                      document.getElementById("btn-adults").click();
                      setEndDate(date);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(startDate.valueOf() + 86400000)}
                    className="font-subtitle  text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none"
                  />
                </div>
              </div>
              <div className="col-span-3  pl-3 border-r border-main-blue py-1">
                <label htmlFor="promocode" className="font-subtitle text-[12px] text-medium-grey uppercase">
                  PROMOCODE
                </label>
                <div className="flex items-center">
                  <Image src={codeIcon} alt="promo" className="mr-2" />
                  <span className="font-title text-xs text-kunuk-grey uppercase lg:hidden">
                    Promo
                  </span>
                  <input
                    id="promocode"
                    type="text"
                    name="promocode"
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder={t("common:booking.promo")}
                    className="font-subtitle text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none"
                  />
                  <input
                    id="promocode-mobile"
                    type="text"
                    name="promocode"
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder={t("common:booking.promo_2")}
                    className="font-subtitle text-[16px] text-kunuk-grey-2 placeholder:text-kunuk-grey-2 w-full outline-none lg:hidden"
                  />
                </div>
              </div>
              <div
                className="col-span-3  pl-3 border-r border-main-blue py-1 relative"
                onClick={() => setIsFromMobile(false)}
                ref={wrapperRef}
              >
                {roomsModalIsOpen && (
                  <GuestModal
                    rooms={rooms}
                    addRoom={addRoom}
                    removeRoom={removeRoom}
                    updateOccupancy={updateOccupancy}
                  />
                )}
                <label htmlFor="guest" className="font-subtitle text-[12px] text-medium-grey uppercase">
                  {t("common:booking.guests")}
                </label>
                <div className="grow flex items-center">
                  <Image src={guestIcon} alt="date" className="mr-2" />
                  <Button
                    id="btn-adults"
                    className="font-subtitle text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none text-left"
                    onClick={() => setRoomsModalOpen(!roomsModalIsOpen)}
                  >
                    <input
                      id="guest"
                      type="text"
                      value={`${rooms?.length} ${roomsText}, ${adults} ${adultsText}`}
                      readOnly
                      className="w-full"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 mr-[70px] md:mr-[45px] lg:ml-0 lg:col-span-2">
            <Link
              href={bookingUrl}
              target="_blank"
              className="base-button bg-main-blue font-subtitle font-medium text-white px-0 w-full text-center hidden lg:block py-4"
              onClick={() => {
                BookingAttempt.post({
                  arrivalDate: startDate,
                  departureDate: endDate,
                  bookingDate: new Date(),
                  totalAdults: parseInt(adults),
                });
              }}
            >
              {t("common:booking.book_now")}
            </Link>
            <Link
              href={"#"}
              className="base-button bg-main-blue h-[60px] font-subtitle flex items-center justify-center font-medium text-white px-0 w-full text-center lg:hidden"
              onClick={() => setModalMobileOpen(true)}
            >
              {t("common:booking.book")}
            </Link>
            <Link
              id="booking-hotel-fly"
              href={bookingTravelApi}
              target="_blank"
              className="hidden"
            >
              {t("common:booking.book")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
